<template>
  <v-card
    class="fill-height"
  >
    <v-card-title
      class="pb-1"
    >
      {{ statisticTitle }}
      <v-spacer></v-spacer>
      <v-icon
        class="text--disabled"
      >
        mdi-help-circle-outline
      </v-icon>
    </v-card-title>
    <v-card-text
      class="fill-height"
    >
    <v-row>
        <v-col
          cols="6"
          class="text-center"
        >
          <div
            class="body-2 font-weight-black"
          >
            {{ totalLabel }}
          </div>
          <div
            class="display-1 font-weight-black"
          >
            {{ totalValue }}
          </div>
        </v-col>
        <v-col
          cols="6"
          class="text-center"
        >
          <div
            class="body-2 font-weight-black"
          >
            {{ partLabel }}
          </div>
          <div
            class="display-1 font-weight-black"
          >
            {{ partValue }}
          </div>
        </v-col>
      </v-row>
      <vue-apex-charts
        :options="chartOptions"
        :series="seriesData"
        type="bar"
        height="240"
      />
    </v-card-text>

  </v-card>
</template>


<script>
  import 'echarts/lib/chart/pie'

  export default{
    name: 'TendencyCardBar',

    components: {

    },

    props: {
      statisticTitle: {
        type: String,
        default: () => ''
      },
      totalValue: {
        type: Number,
        default: () => 0
      },
      partValue: {
        type: Number,
        default: () => 0
      },
      totalLabel: {
        type: String,
        required: true,
        default: () => ''
      },
      partLabel: {
        type: String,
        required: true,
        default: () => ''
      },
      totalRecords: {
        type: Array,
        default: () => []
      },
      warnRecords: {
        type: Array,
        default: () => []
      },
      chartDays: {
        type: Array,
        default: () => []
      },
      color: {
        type: String,
        default: '#FF1744',
      },
      colorTo: {
        type: String,
        default: '#EF9A9A'
      }
    },


    data() {
      return {
        chartOptions: {
            grid: {
                borderColor: '#ebebeb',
                padding: {
                    left: 0,
                    right: 0
                }
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false
            },
            chart: {
                stacked: true,
                type: 'bar',
                toolbar: { show: false },
            },
            colors: ['#64B5F6', '#FF1744'],
            plotOptions: {
                bar: {
                    columnWidth: '10%'
                }
            },
            xaxis: {
                labels: {
                    style: {
                        cssClass: 'text-grey fill-current',
                    }
                },
                axisTicks: {
                    show: false,
                },
                categories: this.chartDays,
                axisBorder: {
                    show: false,
                }
            },
            yaxis: {
                tickAmount: 5,
                labels: {
                    style: {
                        cssClass: 'text-grey fill-current',
                    },
                    formatter: function (val) {
                      return Math.abs(Math.round(val))
                    }
                }
            },
            tooltip: {
                x: { show: false }
            },
        }
      }
    },

    computed: {
      seriesData() {
        return [{
              name: this.totalLabel,
              data: this.totalRecords
          }, {
              name: this.partLabel,
              data: this.warnRecords.map( element => 0-element )
          }]
      }
    },

    methods: {

    },

    created() {

    }
  }
</script>
